.page-title-box {
  display: flex;
  align-items: center;
  min-height: 2.5rem;
  margin: 1rem 0;
  color: $text-muted;
}

.page-title {
  margin: 0;
  font-size: $h3-font-size;
  font-weight: 400;
  line-height: 2.5rem;
}

.page-title-options {
  margin-left: auto;
  color: $text-muted;
}

.page-header {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
  flex-wrap: wrap;
  justify-content: space-between;
}

.nav-icon > svg {
  height: 14px;
  width: 14px;
}

.icon > svg {
  height: 14px;
  width: 14px;
}

table tbody > tr .ant-table-cell {
  max-width: 200px;
}
