.cursor-pointer {
  cursor: pointer;
}

.ant-btn {
  // display: flex;
  align-items: center;

  &.ant-btn-primary {
    background-color: $primary;
    border-color: $primary;
  }
}

.ant-pagination-item-link {
  svg {
    vertical-align: unset;
  }
}

div.ant-table + ul.ant-table-pagination.ant-pagination {
  float: left;
  margin: 16px;
}

// Workaround for missing TailwindCSS classes
@import '../css/tailwind.css';
